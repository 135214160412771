import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import DataTable from "react-data-table-component";
import {withCookies} from "react-cookie";
import {handleGetFetch} from "../common";
import {Row, Container} from "reactstrap";
import DatePicker from './datepicker';
import Button from "reactstrap/lib/Button";
import Col from "reactstrap/lib/Col";


class GenericTable extends Component {


    state = {
        data: [],
        loading: false,
        totalRows: 0,
        page: 0,
        perPage: 10,
        showDatePicker: false
    };

    async componentDidMount() {
        const {perPage} = this.state;
        const {uri} = this.props;

        this.setState({loading: true});
        let sponsorParams = this.props.location.search;

        handleGetFetch(uri + sponsorParams + '&per_page=' + perPage).then((response) => {
            // console.log("Response", response);
            let state = {
                data: response.data.data,
                totalRows: response.data.total,
                loading: false,
            }
            // console.log("STATE", state);
            this.setState(state);
        });

    }

    handlePageChange = async page => {
        const {perPage} = this.state;
        const {uri} = this.props;

        this.setState({loading: true});
        let sponsorParams = this.props.location.search;

        handleGetFetch(uri + sponsorParams + '&page=' + page + '&per_page=' + perPage).then((response) => {
            let state = {
                data: response.data.data,
                totalRows: response.data.total,
                page: page,
                perPage: perPage,
                loading: false,
            }
            this.setState(state);
        });

        // const response = await axios.get(
        //     `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,
        // );

        // this.setState({
        //     loading: false,
        //     data: response.data.data,
        // });
    }

    handlePerRowsChange = async (perPage, page) => {
        this.setState({loading: true});
        const {uri} = this.props;
        let sponsorParams = this.props.location.search;
        let url = uri + sponsorParams + '&page=' + page + '&per_page=' + perPage;
        handleGetFetch(url).then((response) => {
            console.log("Response", response);
            let state = {
                data: response.data.data,
                totalRows: response.data.total,
                loading: false,
                page: page,
                perPage: perPage
            }
            console.log("STATE", state);
            this.setState(state);
        });

    }

    handleSort = (column, sortDirection) => {
        let sponsorParams = this.props.location.search;
        const {uri} = this.props;
        const {perPage, page} = this.state;
        this.setState({loading: true});
        let url = uri + sponsorParams + '&page=' + page + '&per_page=' + perPage + '&sort=' + column.selector + '&direction=' + sortDirection;
        handleGetFetch(url).then((response) => {
            // console.log("Response", response);
            let state = {
                data: response.data.data,
                totalRows: response.data.total,
                loading: false,
            }
            // console.log("STATE", state);
            this.setState(state);
        });
    };
    toggleDatePicker = () => {
        const {showDatePicker} = this.state;
        this.setState({
            showDatePicker: !showDatePicker
        })
    }



    render() {
        const {loading, data, totalRows} = this.state;
        const {columns} = this.props;
        const customStyles = {
            cells: {
                style: {
                    whiteSpace: 'pre-wrap' // unsetWhitespaceWrap
                },
            },
        };

        return (
            <Container fluid>
                <Col md={12}>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={loading}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onSort={this.handleSort}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        customStyles={customStyles}
                    />
                </Col>
            </Container>
        );
    }
}

export default withRouter(withCookies(GenericTable));