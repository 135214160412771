import React from 'react';
import '../App.css';
import {Container, Row, Col, Button} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
// eslint-disable-next-line
import {handlePost, handleGetFetch, returnDollars} from './common.js';
import { FaPencilAlt } from 'react-icons/fa';

class PaymentsAddSinglePayment extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   isLoading: false,
		   organizationId: this.props.organizationId,
		   data: {"periodStart": "01/01/2020", "periodEnd" : "12/31/2020", "profileShare": "10", "paymentName" : ""},
		   comments: "",
		   dataReturned: []
	   }
	   this.handleEditPaymentDetails = this.handleEditPaymentDetails.bind(this);
	   this.handleEditPaymentName = this.handleEditPaymentName.bind(this);
	   this.handleEditPaymentAmount = this.handleEditPaymentAmount.bind(this);
	   this.handleSubmit = this.handleSubmit.bind(this);
	   this.handleCalculate = this.handleCalculate.bind(this);
	}
    /*
	componentDidMount()  {
		this.setState({isLoading: true});
		handleGetFetch("payment?organization_id=" + this.state.organizationId)
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, data: result.data[0]});
		})
	}
	*/
	handleEditPaymentDetails() {
		this.props.openEditPaymentDetails(this.state.data);
	}
	handleEditPaymentName() {
		this.props.openEditPaymentName(this.state.data);
	}
	handleChangeViewMultiple(myView, searchPeriod) {
		this.props.handleChangeViewMultiple(myView, searchPeriod);
	}
	handleEditPaymentAmount() {
		this.props.openEditPaymentAmount(this.state.data);
	}
	handleCalculate() {
	}
	handleSubmit() {
		this.setState({isLoading: true});
		// field names to be determined
		var data = {"??????" : this.state.data};
		// url to be determined
		handlePost("shopify/admin/organization/update", data)
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, dataReturned: result});
		})
	}
	render() {
	  var data=this.state.data;

	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Payment Name:</strong> <Link onClick={this.handleEditPaymentName}>Add</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Amount:</strong> (Calculate from Date Range) <Link onClick={this.handleEditPaymentAmount}>Manually Specify</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={3} className="text-left">
								       <span className="blue-text"><strong>Period Start:</strong> {data.periodStart} <Link onClick={this.handleEditPaymentDetails}>Specify</Link></span>
								    </Col>
								    <Col md={3} className="text-left">
								       <span className="blue-text"><strong>Period End:</strong> {data.periodEnd} <Link onClick={this.handleEditPaymentDetails}>Specify</Link></span>
								    </Col>
								    <Col md={3} className="text-left">
								       <span className="blue-text"><strong>Profile Share:</strong> {data.profileShare}% <Link onClick={this.handleEditPaymentDetails}>Override</Link></span>
								    </Col>
								    <Col md={2} className="text-left">
									   <div className="center-content">
										  <Button type="button" size="sm" color="info" className="default-button" onClick={this.handleCalculate}>CALCULATE</Button>{' '}
									   </div>
								    </Col>
								 </Row>
								 <Row className="ml-1 negative-top-margin-10">
								    <Col md={3} className="text-left">
								       <span className="blue-text"><strong>Result:</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row className="ml-1">
								    <Col md={3} className="text-left">
								       <span className="blue-text"><strong>Orders:</strong> {data.total_sales_count}</span>
								    </Col>
								    <Col md={3} className="text-left">
								       <span className="blue-text"><strong>Total:</strong> {returnDollars(data.total_sales_total)}</span>
								    </Col>
								    <Col md={4} className="text-left">
								       <span className="blue-text"><strong>Share:</strong> {returnDollars(500)} <Link onClick={this.handleChangeViewMultiple.bind(this,'/AdminOrders','range')}>View Orders</Link></span>
								    </Col>
								 </Row>
								 <Row>
								    <span><br/></span>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="red-text"><strong>Amount:</strong> <Link onClick={this.handleEditPaymentAmount}>Specify</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row className="mt-2">
									<Col md={4}>
									   <div className="input-group">
										  <div className="input-group-prepend">
									         <span className="input-group-text" id="basic-addon">
											    <FaPencilAlt />
											 </span>
										  </div>
										  <textarea className="form-control" name="description" rows="5" value={this.state.comment} onChange={this.handleInputChange} placeholder="Add an optional comment."></textarea>
										</div>
									</Col>
								 </Row>
								 <Row>
								    <Col md={3} className="text-left">
									   <div className="center-content">
										  <Button type="button" size="md" color="primary" className="default-button-no-margins" onClick={this.handleSubmit}>SAVE PAYMENT</Button>{' '}
									   </div>
									</Col>
									<Col>
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(PaymentsAddSinglePayment);