import React from 'react';
import '../App.css';
import {Container, Row, Col, Input, Button} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {withCookies, Cookies} from 'react-cookie';
import {instanceOf} from 'prop-types';
import {handleGetFetch, handleInputChange, handleSortClick, firstUpper, returnDollars} from './common.js';
import {FaSort} from 'react-icons/fa';
import GenericTable from "./table/generic-table";

class SponsorPayments extends React.Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);
        let sponsorParams = this.props.location.search;
        this.state = {
            searchInput: "",
            isLoading: false,
            currentPage: 0,
            listings: [],
            sponsorParams: sponsorParams
        }
    }

    render() {
        const columns = [
            {
                name: 'Date',
                selector: 'pay_period',
                sortable: true,
            },
            {
                name: 'Amount',
                selector: 'payment_amount',
                sortable: true,
                // cell: props => returnDollars(props.value)
            },
            {
                name: 'Type',
                selector: 'payment_type'
            },
            {
                name: 'Check Number',
                selector: 'check_number',
                sortable: true,
            },
            {
                name: 'Note',
                selector: 'payment_comment',
                style: { whiteSpace: 'unset' },
            }

        ];

        return (
            <Container fluid>
                <Row>
                    <GenericTable columns={columns} title={"Payments"} uri={"payment"}/>
                </Row>
            </Container>
        )
    }
}

export default withRouter(withCookies(SponsorPayments));