import React from 'react';
import '../App.css';
import {Container, Row, Col} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {handleGetFetch, firstUpper, returnDollars} from './common.js';

class OrganizationsDetail extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };	
	constructor(props){
	   super(props);
	   const { cookies } = props;
       this.state= {
		   isLoading: false,
		   organizationId: cookies.get('organizationId') || "811",
		   data: []
	   }
	   this.handleChangeView = this.handleChangeView.bind(this);
	   this.handleEditDetails = this.handleEditDetails.bind(this);
	   this.handleEditStatus = this.handleEditStatus.bind(this);
	   this.handleEditFundRaisingShare = this.handleEditFundRaisingShare.bind(this);
	   this.handleAddNewAlias = this.handleAddNewAlias.bind(this);
	   this.handleUploadW9 = this.handleUploadW9.bind(this);
	}
	handleChangeView(myView) {
		this.props.handleChangeView(myView);
	}
	handleEditDetails() {
		this.props.openEditDetails(this.state.data);
	}
	handleEditStatus() {
		this.props.openEditStatus(this.state.data);
	}
	handleEditFundRaisingShare() {
		// be sure to convert what ever they enter into decimal (multiply by .1)
		this.props.openEditFundRaisingShare(this.state.data);
	}
	handleAddNewAlias() {
		this.props.openAddNewAlias(this.state.data);
	}
	handleUploadW9() {
		this.props.openUploadW9(this.state.data);
	}
	componentDidMount()  {
		this.setState({isLoading: true});
		handleGetFetch("organization/" + this.state.organizationId)
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, data: result});
		})
	}
	render() {
	  var data=this.state.data;
	  var status=firstUpper(data.organization_status);
	  
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={4} className="text-left">
							     <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray"><strong>{data.organization_name}</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">{data.city} {data.state}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
				  				       <span className="text-normal-gray"><strong>Joined September 15, 2019</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col md={8} className="text-left mt-3">
								       <span className="text-normal-gray">Total Participants</span>
									</Col>
									<Col className="text-left mt-3">
								       <span className="text-normal-gray">423</span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Lifetime:</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Total Revenue</span>
									</Col>
									<Col>
								       <span className="text-normal-gray">{returnDollars(data.sales_subtotal)}</span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Lifetime:</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Total Earnings</span>
									</Col>
									<Col>
								       <span className="text-normal-gray">{returnDollars(1015)}</span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Lifetime:</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							  </Col>
							  <Col md={4} className="text-left">
							     <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">(Open New Ticket)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <span><br/></span>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
 								       <span className="text-normal-gray"><strong>Last Order: December 31, 2019</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col md={8} className="text-left mt-3">
								       <span className="text-normal-gray">Active Participants</span>
									</Col>
									<Col className="text-left mt-3">
								       <span className="text-normal-gray">250</span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Last 12 Months:</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Total Revenue</span>
									</Col>
									<Col>
								       <span className="text-normal-gray">{returnDollars(data.sales_recent_subtotal)}</span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Last 30 Days:</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Total Earnings</span>
									</Col>
									<Col>
								       <span className="text-normal-gray">{returnDollars(205)}</span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">Last 30 Days:</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							  </Col>
							  <Col md={4} className="text-left">
							     <Row>
								    <Col md={12} className="text-left">
 								       <span className="text-normal-gray"><strong>Status: {status}</strong> <Link onClick={this.handleEditStatus}>Change</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray"><strong>Fundraising Share: 10%</strong> <Link onClick={this.handleEditFundRaisingShare}>Change</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								  <Row>
									  <Col md={12} className="text-left">
										  <span className="text-normal-gray"><strong>Address</strong></span>
									  </Col>
									  <Col>
									  </Col>
								  </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray">{data.address_1} {data.address2}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={8} className="text-left">
								       <span className="text-normal-gray">{data.city}, {data.state} {data.postal_code}</span>
								    </Col>
									<Col md={1} className="text-left">
								       <span className="text-normal-gray"><Link onClick={this.handleEditDetails}>Edit</Link></span>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray">(view map)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray">{data.phone}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray">{data.email}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray"><strong>Sponsors</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray">Abe Simpson, Marge Bouvier</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col md={12} className="text-left">
								       <span className="text-normal-gray"><strong><strong>W9 STATUS: On File</strong></strong> (view) <Link onClick={this.handleUploadW9}>Update</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
				  <Row>
					  <div className="section-detail">
						 <Row>
							 <Col md={12} className="text-left">
								<Row>
								   <Col md={12} className="text-left">
									  <span className="text-normal-gray"><strong>Primary Participant Tag:</strong> SCHOOL: {data.primary_tag} (copy)</span>
								   </Col>
								</Row>							  
							 </Col>
					      </Row>
				      </div>
				  </Row>
				  <Row>
					  <div className="section-detail">
						 <Row>
							 <Col md={12} className="text-left">
								<Row>
								   <Col md={12} className="text-left">
								      <Row>
										 <Col>
									        <span className="text-normal-gray"><strong>Alias Participant Tags:</strong> SCHOOL: MAIN STREET ELEM - CHICAGO, ILLINOIS</span>
										 </Col>
										 <Col>
											<span className="text-normal-gray">SCHOOL: MAIN STREET PTA - CHICAGO, ILLINOIS <Link onClick={this.handleAddNewAlias}>Add New Alias</Link></span>			
										 </Col>
									  </Row>
								   </Col>
								</Row>							  
							 </Col>
					      </Row>
				      </div>
				  </Row>
				  <Row>
					  <div className="section-detail">
						 <Row>
							 <Col md={12} className="text-left">
								<Row>
								   <Col md={12} className="text-left">
									  <div className="text-normal-gray border-bottom"><strong>Sponsor Tag:</strong> SPONSOR: MAIN STREET ELEMENTARY - CHICAGO, ILLINOIS (copy)</div>
								   </Col>
								</Row>							  
							 </Col>
					      </Row>
				      </div>
				  </Row>
				  <Row>
					  <div className="section-detail">
						 <Row>
							 <Col md={12} className="text-left">
								<Row>
								   <Col md={12} className="text-left">
									  <span className="text-normal-gray"><strong>View:</strong> 
									     <Link onClick={this.handleChangeView.bind(this,'/OrganizationsOrders')}> Orders</Link> | 
									     <Link onClick={this.handleChangeView.bind(this,'/OrganizationsCustomers')}> Customers</Link> | 
									     <Link onClick={this.handleChangeView.bind(this,'/OrganizationsPayments')}> Payments</Link> |
									     <Link onClick={this.handleChangeView.bind(this,'/OrganizationsAdminNotes')}> Admin Notes</Link> | 
									     <Link onClick={this.handleChangeView.bind(this,'/OrganizationsAdminActivityLog')}> Activity Log</Link> 
									  </span>
								   </Col>
								</Row>							  
							 </Col>
					      </Row>
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(withCookies(OrganizationsDetail));