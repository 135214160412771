import React from 'react';
import '../App.css';
import {Container, Row, Col} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import {returnDollars} from "./common.js";

class PaymentDetail extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   data: []
	   }
	   this.openMarkAsPaid = this.openMarkAsPaid.bind(this);
	   this.openCancelPayment = this.openCancelPayment.bind(this);
	}
	openMarkAsPaid() {
		this.props.openMarkAsPaid(null);
	}
	openCancelPayment() {
		this.props.openCancelPayment(null);
	}
	render() {
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
							     <Row>
								    <Col className="text-left">
 								       <span className="text-normal-gray"><strong>Main Street Elementary</strong> (view profile)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray">Chicago Illinois</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Status:</strong> Pending <Link onClick={this.openMarkAsPaid}>Mark As Paid</Link> <Link onClick={this.openCancelPayment}>Cancel Payment</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Order Total:</strong> {returnDollars(1005.20)} / 25 Orders (View orders)</span>
								    </Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Share:</strong> 10.0%</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Share Amount:</strong> {returnDollars(100.52)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Period Name:</strong> December 2019</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Period Start:</strong> 12/1/2019 <strong>Period End:</strong> 12/31/2019</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Payment Type:</strong> Check - # Unavailable (Add check number)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Payment Date:</strong> Pending</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Comment:</strong> (Add/edit comment)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(PaymentDetail);