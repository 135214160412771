import React from 'react';
import '../App.css';
import {Container, Input, Button} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {handleInputChange} from './common.js';

// will be used with admin only. sponsor has it's own authentication method.
class Login extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   isLoading: false,
		   userName: "",
		   passWord: ""
	   }
	   this.handleChangeView = this.handleChangeView.bind(this);
	   this.handleLogin = this.handleLogin.bind(this);
	}
	handleChangeView(myView) {
		this.props.handleChangeView(myView);
	}
	handleLogin() {
		// temporarily using password field to specify "admin" or "sponsor". Will need to add actual type later.
		this.props.handleLogin(this.state.userName,this.state.passWord);
	}
	render() {
	  return (
		  <>
			  <Container fluid>
				  <div className="center-login">
				     <div className="title-black title-small text-center">Market Day Fundraising Manager (MDFM)</div>
					 <div className="title-black title-small mb-3 text-center">Management Interface</div>
					 <div className="login-box">
					    <div className="text-center mt-2 mb-4">
						   <strong>LOGIN HERE</strong>
						</div>
						<div>
		  				   <Input name="userName" className="login-field ml-2 mb-2" type="text" value={this.state.userName} onChange={handleInputChange.bind(this)} placeholder="Username"/>
						</div>
						<div>
		  				   <Input name="passWord" className="login-field ml-2" type="text" value={this.state.passWord} onChange={handleInputChange.bind(this)} placeholder="Password"/>
						</div>
						<div>
						   <Button type="button" size="md" color="success" className="login-button mt-3 ml-2" onClick={this.handleLogin}>Submit</Button>{' '}
						</div>
					 </div>
				  </div>
			  </Container>
		  </>
		)
	}
}

export default withRouter(Login);