import React from 'react';
import '../App.css';
import {Container, Row, Col, Input, Button} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import {handleGetFetch, handleInputChange, handleSortClick, firstUpper, returnDollars} from './common.js';
import { FaSort } from 'react-icons/fa';

class OrganizationsList extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   searchInput: "",
		   isLoading: false,
		   currentPage: 0,
		   listings: []
	   }
	   this.handleSelectOrganization = this.handleSelectOrganization.bind(this);
	   this.handleSearchInput = this.handleSearchInput.bind(this);
	}
	componentDidMount()  {
		this.setState({isLoading: true});
		handleGetFetch("organization")
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, currentPage: result.current_page, listings: result.data});
		})
	}
	handleSelectOrganization(data) {
		this.props.handleViewOrganization(data);
	}
	handleSearchInput() {
		this.setState({isLoading: true});
		handleGetFetch("organization?q=" + this.state.searchInput)
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, currentPage: result.current_page, listings: result.data});
		})
	}
	render() {
      let columnData = {
		  "organization_name": "Organization Name",
		  "organization_status": "Status",
		  "total_participants": "Total Participants",
		  "active_participants": "Active Participants",
          "sales_subtotal": "Total Revenue $",
	      "sales_recent_subtotal": "Recent $"
	  }			 
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border">
						  <Row>
							  <Col md={9}>
								 <div className="center-content">
  							        <Input name="searchInput" type="text" className="search-field" value={this.state.searchInput} onChange={handleInputChange.bind(this)} ref={(input) => { this.searchInput = input; }} placeholder="Search"/>
							     </div>
							  </Col>
							  <Col md={2}>
								 <div className="center-content">
								    <Button type="button" size="md" color="primary" className="default-button" onClick={this.handleSearchInput}>FILTER</Button>{' '}
								 </div>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
				  <Row>
					  <div className="content-section-no-border">
						  <Listing data={columnData} header={true} handleSortClick={handleSortClick}/>
						  {this.state.listings &&
						     this.state.listings.map((data) => (<Listing key={data.id} data={data} handleSelectOrganization={this.handleSelectOrganization}/>))
						  }
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export class Listing extends React.Component{
  render(){
	var data=this.props.data;
	var name;
	var status=firstUpper(data.organization_status);
	var totalParticipants=data.total_participants;
	var activeParticipants=data.active_participants;
	var totalRevenue=this.props.header ? data.sales_subtotal : returnDollars(data.sales_subtotal);
	var recentRevenue=this.props.header ? data.sales_recent_subtotal : returnDollars(data.sales_recent_subtotal);
	
	if (!this.props.header) {
		name = <Link onClick={this.props.handleSelectOrganization.bind(this,data)}>{data.organization_name}</Link>;
	}
	else {
		name = data.organization_name;
	}
    return(
       <Row>
		  <Col md={4} className="text-left">
		     <span className="text-normal-gray">{name}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,0)}><FaSort/></button>}</span>
	      </Col>
		  <Col md={1} className="text-left">
		     <span className="text-normal-gray">{status}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,1)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{totalParticipants}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,2)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{activeParticipants}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,3)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{totalRevenue}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,4)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{recentRevenue}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,5)}><FaSort/></button>}</span>
		  </Col>
	   </Row>
     )
  }
}

export default withRouter(OrganizationsList);