import React from 'react';
import '../App.css';
import {Container, Row, Col} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import {handleGetFetch, firstUpper} from './common.js';

class SponsorProfile extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };	
	constructor(props){
	   super(props);
	   let sponsorParams = this.props.location.search;
	   console.log("sponsorParams", sponsorParams);
       this.state= {
		   isLoading: false,
		   data: [],
		   sponsors: [],
		   sponsorParams: sponsorParams,
		   organizationId: this.props.organizationId
	   }
	   this.openEditPhone = this.openEditPhone.bind(this);
	   this.openEditEmail = this.openEditEmail.bind(this);
	   this.handleUploadW9 = this.handleUploadW9.bind(this);
	}
	openEditPhone() {
		// once data is being loaded, a copy of data gets passed in next function call
		this.props.openEditPhone(this.state.data.phone);
	}
	openEditEmail() {
		// once data is being loaded, a copy of data gets passed in next function call
		this.props.openEditEmail(this.state.data.email);
	}
	handleUploadW9() {
		this.props.openUploadW9(this.state.organizationId);
	}
	componentDidMount()  {
		console.log("DidMount",this.state.sponsorParams);
		let url = "organization" + this.state.sponsorParams;
		this.setState({isLoading: true});
		handleGetFetch(url)
		.then((result) => {
  		   console.log(result);	
		   this.setState({isLoading: false, data: result.data, sponsors: result.data.sponsors});
		})
	}
	render() {
	  var data=this.state.data;
	  var sponsors = this.state.sponsors;
	  var names = "";
	  Object.keys(sponsors).forEach(function (item) {
		 names += sponsors[item]["first_name"] + " " + sponsors[item]["last_name"];
		 if (item + 1 < Object.keys(sponsors).length) {
			 names += ", ";
		 }
	  });
	  var status = firstUpper(data.organization_status);
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={8} className="text-left">
							     <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>{data.organization_name}</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray">{data.city} {data.state}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
				  				       <span className="text-normal-gray"><strong>Joined September 15, 2019</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col className="text-left mt-3">
 								       <span className="text-normal-gray"><strong>Status: {status}</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Fundraising Share: 10%</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								  <Row>
									  <Col className="text-left mt-3">
										  <span className="text-normal-gray"><strong>Address:</strong></span>
									  </Col>
									  <Col>
									  </Col>
								  </Row>
								 <Row>
									  <Col className="text-left">
									  <span className="text-normal-gray">{data.address_1} {data.address_2}</span>
							  </Col>
									  <Col>
							  </Col>
								  </Row>
								  <Row>
								  <Col className="text-left mt-3">
									  <span className="text-normal-gray"><strong>Primary Contact Information</strong></span>
								  </Col>
								  <Col>
								  </Col>
							  </Row>
								  <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray">{data.city}, {data.state} {data.postal_code}</span>
								    </Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray">{data.phone} <Link to={'#'} onClick={this.openEditPhone}>Change</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray">{data.email} <Link onClick={this.openEditEmail}>Change</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Sponsors</strong></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray">{names}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 {/*<Row>*/}
								 {/*   <Col className="text-left">*/}
								 {/*      <span className="text-normal-gray"><strong><strong>W9 STATUS: Missing</strong></strong> <Link onClick={this.handleUploadW9}>Upload</Link></span>*/}
									{/*</Col>*/}
									{/*<Col>*/}
									{/*</Col>*/}
								 {/*</Row>*/}
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(withCookies(SponsorProfile));