import React from 'react';
import '../App.css';
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';

// various libaries for the different views. No sponsor libraries are called from here. Only admin side.
import AdminDashBoard from './admin-dashboard.js';
import OrganizationsList from './organizations-list';
import OrganizationsDetail from './organizations-detail';
import OrganizationsOrders from './organizations-orders';
import OrganizationsCustomers from './organizations-customers';
import OrganizationsPayments from './organizations-payments';
import OrganizationsAdminNotes from './organizations-admin-notes';
import OrganizationsAdminActivityLog from './organizations-admin-activity-log';
import OrganizationsEditDetails from './organizations-edit-details';
import OrganizationsEditStatus from './organizations-edit-status';
import OrganizationsEditFundRaisingShare from './organizations-edit-fundraising-share';
import OrganizationsPaymentAmount from './organizations-payment-amount';
import OrganizationsAddNewAlias from './organizations-add-new-alias';
import UploadW9 from './upload-w9';
import PaymentsList from './payments-list';
import PaymentDetail from './payment-detail';
import PaymentsAddSinglePayment from './payments-add-single-payment';
import PaymentsRunPaymentBatch from './payments-run-payment-batch';
import PaymentsEditDetails from './payments-edit-details';
import PaymentsEditName from './payments-edit-name';
import PaymentsMarkAsPaid from './payments-mark-as-paid';
import PaymentsCancelPayment from './payments-cancel-payment';
import AdminOrders from './admin-orders';
import Login from './login';

class Admin extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };	
	constructor(props){
	   super(props);
   	   const { cookies } = props;
       // props.myView is passed by App.js after App.js pulls the cookie
	   // organization contains the name of the current organization
	   // organizationId contains the id of the current organization
	   // searchCriteria is mtd (month to day), last-month
	   // userName is set by login or obtained from shopify side and displayed on console
	   // userType is either admin or sponsor. This variable may be dropped later depending on final implementations
	   // modalTitle, modalBody and modalSize are used with the popup modal screens
	   // modal indicates is a modal is open or not at the time
       this.state= {
		   myView: this.props.myView,
		   organization: cookies.get('organization') || "",
		   organizationId: cookies.get('organizationId') || "811",
		   searchCriteria: cookies.get('searchCriteria') || "mtd", 
		   userName: cookies.get('userName') || "",
		   userType: cookies.get('userType') || "",
		   modalTitle: "",
		   modalBody: "",
		   modelSize: "md",
		   modal: false,
	   }
	   // bind various functions so they get use of "this"
	   this.handleDashBoard = this.handleDashBoard.bind(this);
	   this.handleSponsors = this.handleSponsors.bind(this);
	   this.handleChangeView = this.handleChangeView.bind(this);
	   this.handleChangeViewMultiple = this.handleChangeViewMultiple.bind(this);
	   this.handleViewOrganization = this.handleViewOrganization.bind(this);
	   this.handleLogOut = this.handleLogOut.bind(this);
	   this.handleClose = this.handleClose.bind(this);
	   this.openEditDetails = this.openEditDetails.bind(this);
	   this.openEditStatus = this.openEditStatus.bind(this);
	   this.openEditFundRaisingShare = this.openEditFundRaisingShare.bind(this);
	   this.openEditPaymentDetails = this.openEditPaymentDetails.bind(this);
	   this.openEditPaymentName = this.openEditPaymentName.bind(this);
	   this.openEditPaymentAmount = this.openEditPaymentAmount.bind(this);
	   this.openAddNewAlias = this.openAddNewAlias.bind(this);
	   this.openUploadW9 = this.openUploadW9.bind(this);
	   this.openMarkAsPaid = this.openMarkAsPaid.bind(this);
	   this.openCancelPayment = this.openCancelPayment.bind(this);
	   this.toggle = this.toggle.bind(this);
	   this.setModal = this.setModal.bind(this);
	   this.handleLogin = this.handleLogin.bind(this);
	}
	handleDashBoard() {
	   // change view to main dashboard
	   this.props.history.push('/DashBoard');
	   this.handleChangeView('/DashBoard');
	}
	handleChangeView(myView) {
		// set myView state variable to change screen view
		this.setState({myView: myView});
	    const { cookies } = this.props;
	    cookies.set('adminView', myView, { path: '/' });
	}
	handleChangeViewMultiple(myView, searchPeriod) {
		// set myView state variable and adminView cookie to change screen view
        // set searchPeriod cookie for use in View Orders screen view
		this.handleChangeView(myView);
		this.setState({searchPeriod: searchPeriod});
	    const { cookies } = this.props;
	    cookies.set('searchPeriod', searchPeriod, { path: '/' });
	}
	handleSponsors() {
	   // this will be dropped once in production. Here temporarily to allow jumping from admin/sponsor
	   const { cookies } = this.props;
	   this.props.history.push('/Sponsors');
	   cookies.set('sponsorView', "/DashBoard", { path: '/' });
	}
	handleViewOrganization(data) {
	   // Show organization detail view and set appropriate cookies
	   this.setState({myView: '/OrganizationsDetail', organization: data.organization_name, organizationId: data.id});
	   const { cookies } = this.props;
	   this.handleChangeView('/OrganizationsDetail');
	   cookies.set('organization', data.organization_name, { path: '/' });
	   cookies.set('organizationId', data.id, { path: '/' });
	}
	handleLogOut() {
	   // remove all cookies and reset default view back to dashboard
	   const { cookies } = this.props;
	   cookies.remove('organization', { path: '/' });
	   cookies.remove('organizationId', { path: '/' });
	   cookies.remove('userName', { path: '/' });
	   cookies.remove('userType', { path: '/' });
	   cookies.set('sponsorView', "/DashBoard", { path: '/' });
	   this.setState({userName: "", userType: ""});
	   this.handleChangeView("/DashBoard");
	}
    handleClose() {
		// close modal window
	    this.toggle();
    }
	toggle() {
	    // toggle modal window open/closed
	    this.setState(prevState => ({modal: !prevState.modal}))
	}
	setModal(title, body, size) {
	  // set contents of modal window
	  this.setState({
		 modalTitle:title,
		 modalBody:body,
		 modalSize:size
	  });
	}	
    openEditDetails(data) {
		// open edit organization details modal
        this.setModal("Edit Organization Details", <OrganizationsEditDetails data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openEditStatus(data) {
		// open change organization status modal
        this.setModal("Edit Organization Status", <OrganizationsEditStatus data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openEditFundRaisingShare(data) {
		// open edit fundraising share modal
        this.setModal("Edit Fundraising Share", <OrganizationsEditFundRaisingShare data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openEditPaymentDetails(data) {
		// open edit payment details modal
        this.setModal("Edit Payment Details", <PaymentsEditDetails data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openEditPaymentName(data) {
		// open edit payment name modal
        this.setModal("Edit Payment Name", <PaymentsEditName data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openEditPaymentAmount(data) {
		// open edit payment amount modal
        this.setModal("Edit Payment Amount", <OrganizationsPaymentAmount data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openAddNewAlias(data) {
		// open add alias modal
        this.setModal("Add New Alias", <OrganizationsAddNewAlias data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openUploadW9(data) {
		// open upload w9 file modal
        this.setModal("Upload W9 Document", <UploadW9 data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openMarkAsPaid(data) {
		// open payments mark as paid modal
        this.setModal("Mark As Paid", <PaymentsMarkAsPaid data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openCancelPayment(data) {
		// open cancel payment modal
        this.setModal("Cancel Payment", <PaymentsCancelPayment data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
	handleLogin(userName, userType) {
		// this will be revamped to only handle admin login. Right now it goes to admin and sponsor dashboards.
	    const { cookies } = this.props;
	    cookies.remove('organization', { path: '/' });
	    cookies.set('userName', userName, { path: '/' });
	    cookies.set('userType', userType, { path: '/' });
		this.setState({userName: userName, userType: userType});
	    if (userType === 'admin') {
			this.handleDashBoard();
		}
		else {
			this.handleSponsors();
		}
	}
	render() {
      let myView = this.state.myView;

	  // if userName or userType are not set, show login screen
      if (!this.state.userType || !this.state.userName) {
          return <Login handleLogin={this.handleLogin}/>
	  }
	  return (
		  <>
			  <Container fluid>
				  <div className="sticky-item">
					  <Modal isOpen={this.state.modal} toggle={this.toggle} scrollable={true} ref={el => (this.modal = el)} size={this.state.modalSize}>
						  <ModalHeader toggle={this.toggle}>{this.state.modalTitle}</ModalHeader>
						  <ModalBody>
							{this.state.modalBody}
						  </ModalBody>
					  </Modal>
				  </div>
				  <Row>
					  <div className="header-section">
						  <Row>
							  <Col className="text-left">
								 <span className="header-title">Market Day Fundraising Manager (MDFM)</span>
							  </Col>
							  <Col className="text-right">
								 <span className="text-normal-gray">Logged in as {this.state.userName} (<Link onClick={this.handleLogOut}>Logout</Link>)</span>
							  </Col>
						  </Row>	
						  <Row>
							  <Col className="text-left">
								 <span className="text-normal-gray">Version 1.0 Copyright 2020</span>
							  </Col>
							  <Col className="text-right">
								 <span className="text-normal-gray">Profile | <Link onClick={this.handleDashBoard}>Admin Control Panel</Link></span>
							  </Col>
						  </Row>
				      </div>
				  </Row>
				  <Row>
					  <div className="section-no-border">
						  <Row>
							  <Col md={2}>
								 <Button type="button" size="lg" color="primary" onClick={this.handleDashBoard} className="dashboard-button">Dashboard</Button>{' '}
								 <Button type="button" size="lg" color="primary" onClick={this.handleChangeView.bind(this,'/OrganizationsList')} className="dashboard-button">Organizations</Button>{' '}
								 <Button type="button" size="lg" color="primary" onClick={this.handleChangeView.bind(this,'/PaymentsList')} className="dashboard-button">Payments</Button>{' '}
								 <Button type="button" size="lg" color="primary" onClick={this.handleSponsors} className="dashboard-button">Sponsors</Button>{' '}
								 <div className="position-bottom">
								    <Button type="button" size="lg" color="primary" className="dashboard-button">Settings</Button>{' '}
							     </div>
							  </Col>
							  <Col md={10}>
								 <div className="content-section">
									{myView.includes("/DashBoard") &&
									   <AdminDashBoard handleChangeViewMultiple={this.handleChangeViewMultiple}/>
									}
									{myView.includes("/OrganizationsList") &&
									   <OrganizationsList handleViewOrganization={this.handleViewOrganization}/>
									}
									{myView.includes("/OrganizationsDetail") &&
									   <OrganizationsDetail handleChangeView={this.handleChangeView} openEditDetails={this.openEditDetails} openEditStatus={this.openEditStatus} openEditFundRaisingShare={this.openEditFundRaisingShare} openAddNewAlias={this.openAddNewAlias} openUploadW9={this.openUploadW9}/>
									}
									{myView.includes("/OrganizationsOrders") &&
									   <OrganizationsOrders handleChangeView={this.handleChangeView}/>
									}
									{myView.includes("/OrganizationsCustomers") &&
									   <OrganizationsCustomers handleChangeView={this.handleChangeView}/>
									}
									{myView.includes("/OrganizationsPayments") &&
									   <OrganizationsPayments handleChangeView={this.handleChangeView}/>
									}
									{myView.includes("/OrganizationsAdminNotes") &&
									   <OrganizationsAdminNotes handleChangeView={this.handleChangeView}/>
									}
									{myView.includes("/OrganizationsAdminActivityLog") &&
									   <OrganizationsAdminActivityLog handleChangeView={this.handleChangeView}/>
									}
									{myView.includes("/PaymentsList") &&
									   <PaymentsList handleChangeView={this.handleChangeView} openMarkAsPaid={this.openMarkAsPaid}/>
									}
									{myView.includes("/PaymentsDetail") &&
									   <PaymentDetail openMarkAsPaid={this.openMarkAsPaid} openCancelPayment={this.openCancelPayment}/>
									}
									{myView.includes("/PaymentsAddSinglePayment") &&
									   <PaymentsAddSinglePayment organizationId={this.state.organizationId} openEditPaymentDetails={this.openEditPaymentDetails} openEditPaymentName={this.openEditPaymentName} openEditPaymentAmount={this.openEditPaymentAmount} handleChangeViewMultiple={this.handleChangeViewMultiple}/>
									}
									{myView.includes("/PaymentsRunPaymentBatch") &&
									   <PaymentsRunPaymentBatch organizationId={this.state.organizationId} openEditPaymentDetails={this.openEditPaymentDetails} openEditPaymentName={this.openEditPaymentName} handleChangeViewMultiple={this.handleChangeViewMultiple}/>
									}
									{myView.includes("/AdminOrders") &&
									   <AdminOrders searchCriteria={this.state.searchCriteria}/>
									}
								 </div>
							  </Col>
						  </Row>
					  </div>
				  </Row>
				  <Row>
					  <div className="footer-section">
						  <Row>
							 <div className="center-content">
								<span className="text-normal-gray">Activity & Error Log | Last synced with Shopify: 12 hours ago (1-27-19 9am MDT)</span>
							 </div>
						  </Row>	
						  <Row>
							 <div className="center-content">
								<span className="text-normal-gray">Version 1.0 Copyright 2020</span>
							 </div>
						  </Row>
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(withCookies(Admin));