import React from 'react';
import '../App.css';
import {Container, Row, Col} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import {handleGetFetch, returnDollars} from './common.js';

class AdminDashBoard extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   isLoading: false,
		   data: []
	   }
	}
	handleChangeView(myView) {
		this.props.handleChangeView(myView);
	}
	handleChangeViewMultiple(myView, searchPeriod) {
		this.props.handleChangeViewMultiple(myView, searchPeriod);
	}
	componentDidMount()  {
		this.setState({isLoading: true});
		let url = "admin/dashboard"
		handleGetFetch(url)
		.then((result) => {
  		   console.log(result);	
		   // json array returned  
		   this.setState({isLoading: false, data: result.data});
		})
	}
	render() {
	  var data=this.state.data;
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
							     <Row>
								    <Col className="text-left">
 								       <span className="text-normal-gray"><strong>NEW APPLICATIONS TO REVIEW:</strong> {data.applications_to_review} (REVIEW)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>ACTIVE ORGANIZATIONS:</strong> {data.organizations_active} <Link onClick={this.handleChangeView.bind(this,'/OrganizationsList')}>REVIEW</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Month to Date Sales:</strong> {returnDollars(data.sales_current_month_total)} ({data.sales_current_month_count} Sales) <Link onClick={this.handleChangeViewMultiple.bind(this,'/AdminOrders','mtd')}>View Orders</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Registered Users:</strong> {data.registered_users} ({data.registered_users_active} active in last 12 months)</span>
								    </Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Estimated Share (@ {data.sales_current_month_share_percentage * 100}%):</strong> {returnDollars(data.sales_current_month_share_total)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Last Month's Sales:</strong> {returnDollars(data.sales_last_month_total)} ({data.sales_last_month_count} sales) <Link onClick={this.handleChangeViewMultiple.bind(this,'/AdminOrders','last-month')}>View Orders</Link></span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Estimated Share (@ {data.sales_last_month_share_percentage * 100}%):</strong> {returnDollars(data.sales_last_month_share_total)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Year to Date Sales:</strong> {returnDollars(data.sales_year_to_date)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Lifetime Payouts:</strong> {returnDollars(data.lifetime_payouts)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(AdminDashBoard);