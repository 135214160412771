import React from 'react';
import '../App.css';
import {Container, Row, Col, Input, Button} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {handleGetFetch, handleInputChange, handleSortClick, firstUpper} from './common.js';
import { FaSort } from 'react-icons/fa';
import GenericTable from "./table/generic-table";

class OrganizationsOrders extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };	
	constructor(props){
	   super(props);
	   const { cookies } = props;
       this.state= {
		   searchInput: "",
		   isLoading: false,
		   currentPage: 0,
		   listings: [],
		   organizationId: cookies.get('organizationId') || "811",
	   }
	   this.handleChangeView = this.handleChangeView.bind(this);
	}
	componentDidMount()  {
		this.setState({isLoading: true});
		handleGetFetch("salesOrder?organization_id=" + this.state.organizationId)
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, currentPage: result.data.current_page, listings: result.data.data});
		})
	}
	handleChangeView(myView) {
		this.props.handleChangeView(myView);
	}
	render() {
      let columnData = {
		  "shopify_order_number": "Order #",
		  "shopify_order_date": "Date",
		  "order_total": "Amount",
		  "shopify_fulfillment_status": "Status",
		  "shopify_payment_status": "FR Paid?"
	  }			 
	  return (
		  <>
			  <Container fluid>
				  <GenericTable/>
				  {/*<Row>*/}
					{/*  <div className="section-no-border">*/}
					{/*	  <Row>*/}
					{/*		  <Col md={8}>*/}
					{/*			 <div className="center-content">*/}
  				{/*			        <Input name="searchInput" type="text" className="search-field" value={this.state.searchInput} onChange={handleInputChange.bind(this)} ref={(input) => { this.searchInput = input; }} placeholder="Search"/>*/}
					{/*		     </div>*/}
					{/*		  </Col>*/}
					{/*		  <Col md={2}>*/}
					{/*			 <div className="center-content">*/}
					{/*			    <Button type="button" size="md" color="primary" className="default-button">FILTER</Button>{' '}*/}
					{/*			 </div>*/}
					{/*		  </Col>*/}
					{/*	  </Row>	*/}

				  {/*    </div>*/}
				  {/*</Row>*/}
				  {/*<Row>*/}
					{/*   <Col md={12}>*/}
				  {/*        <Link onClick={this.handleChangeView.bind(this,'/OrganizationsDetail')}>Return to Organization Overview</Link>*/}
					{/*   </Col>*/}
				  {/*</Row>*/}
				  {/*<Row>*/}
					{/*  <div className="content-section-no-border">*/}
					{/*	  <Listing data={columnData} header={true} handleSortClick={handleSortClick}/>*/}
					{/*	  {this.state.listings &&*/}
					{/*	     this.state.listings.map((data) => (<Listing key={data.shopify_order_id} data={data}/>))*/}
					{/*	  }*/}
				  {/*    </div>*/}
				  {/*</Row>*/}
			  </Container>
		  </>
		)
	}
}

export class Listing extends React.Component{
  render(){
	var data=this.props.data;
	var orderNumber=data.shopify_order_number;
	var date=data.shopify_order_date;
	var amount=data.order_total;
	var status=firstUpper(data.shopify_fulfillment_status);
	var frPaid=firstUpper(data.shopify_payment_status);

    return(
       <Row>
		  <Col className="text-left">
		     <span className="text-normal-gray">{orderNumber}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,0)}><FaSort/></button>}</span>
	      </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{date}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,1)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{amount}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,2)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{status}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,3)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{frPaid}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,4)}><FaSort/></button>}</span>
		  </Col>
	   </Row>
     )
  }
}

export default withRouter(withCookies(OrganizationsOrders));