import React from 'react';
import '../App.css';
import {Container, Row, Col, Input, Button} from 'reactstrap';
import {withRouter} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {handleGetFetch, handleInputChange, handleSortClick, firstUpper, returnDollars} from './common.js';
import { FaSort } from 'react-icons/fa';
import GenericTable from "./table/generic-table";

class SponsorOrders extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };
	constructor(props){
	   super(props);
	   let sponsorParams = this.props.location.search;
       this.state= {
		   searchInput: "",
		   isLoading: false,
		   currentPage: 0,
		   listings: [],
		   sponsorParams: sponsorParams
	   }
	}
	componentDidMount() {
		this.setState({isLoading: true});
		handleGetFetch("sponsor/salesOrder" + this.state.sponsorParams)
			.then((result) => {
				this.setState({isLoading: false, currentPage: result.data.current_page, listings: result.data.data});
			})
	}
	render() {
		const columns = [
			{
				name: 'Order #',
				selector: 'shopify_order_number',
				sortable: true
			},
			{
				name: 'Date',
				selector: 'shopify_order_date',
				sortable: true
			},
			{
				name: 'First Name',
				selector: 'first_name',
				sortable: true,
			},
			{
				name: 'Last Name',
				selector: 'last_name',
				sortable: true,
			},
			{
				name: 'Amount',
				selector: 'order_total',
				sortable: true,
			},
		];
	  return (
		  <>
			  <Container fluid>
				  <Row>
				  <GenericTable columns={columns} title={"Orders"} uri={"sponsor/salesOrder"}/>
				  {/*<Row>*/}
					{/*  <div className="section-no-border">*/}
					{/*	  <Row>*/}
					{/*		  <Col md={8}>*/}
					{/*			 <div className="center-content">*/}
  				{/*			        <Input name="searchInput" type="text" className="search-field" value={this.state.searchInput} onChange={handleInputChange.bind(this)} ref={(input) => { this.searchInput = input; }} placeholder="Search"/>*/}
					{/*		     </div>*/}
					{/*		  </Col>*/}
					{/*		  <Col md={2}>*/}
					{/*			 <div className="center-content">*/}
					{/*			    <Button type="button" size="md" color="primary" className="default-button">FILTER</Button>{' '}*/}
					{/*			 </div>*/}
					{/*		  </Col>*/}
					{/*	  </Row>*/}
				  {/*    </div>*/}
				  {/*</Row>*/}
				  {/*<Row>*/}
					{/*  <div className="content-section-no-border">*/}
					{/*	  <Listing data={columnData} header={true} handleSortClick={handleSortClick}/>*/}
					{/*	  {this.state.listings &&*/}
					{/*	     this.state.listings.map((data) => (<Listing key={data.shopify_order_id} data={data}/>))*/}
					{/*	  }*/}
				  {/*    </div>*/}
				  {/*</Row>*/}
				  </Row>
			  </Container>
		  </>
		)
	}
}

export class Listing extends React.Component{
  render(){
	var data=this.props.data;
	var orderNumber=data.shopify_order_number;
	var date=data.shopify_order_date;
	var name=data.customer.first_name + " " + data.customer.last_name;
	var amount=this.props.header ? data.order_total : returnDollars(data.order_total);
	var frPaid=firstUpper(data.shopify_payment_status);

    return(
       <Row>
		  <Col className="text-left">
		     <span className="text-normal-gray">{orderNumber}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,0)}><FaSort/></button>}</span>
	      </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{date}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,1)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{name}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,2)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{amount}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,3)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{frPaid}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,4)}><FaSort/></button>}</span>
		  </Col>
	   </Row>
     )
  }
}

export default withRouter(withCookies(SponsorOrders));