import React from 'react';
import '../App.css';
import {Container, Input, Button, Row, Col} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {handlePost, handleInputChange} from './common.js';

class OrganizationsEditDetails extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   isLoading: false,
		   address1: this.props.data.address_1,
		   address2: this.props.data.address_2,
		   city: this.props.data.city,
		   state: this.props.data.state,
		   postalCode: this.props.data.postal_code,
		   phone: this.props.data.phone,
		   email: this.props.data.email,
		   dataReturned: []
	   }
	   this.handleClose = this.handleClose.bind(this);
	   this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleClose() {
		this.props.handleClose();
	}
	handleSubmit() {
		this.setState({isLoading: true});
		// field names to be determined
		var data = {"address1" : this.state.address1,
					"address2" : this.state.address2,
					"city" : this.state.city,
					"state" : this.state.state,
					"postal_code" : this.state.postalCode,
					"phone" : this.state.phone,
					"email" : this.state.email
		};
		// url to be determined
		handlePost("shopify/admin/organization/update", data)
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, dataReturned: result});
		})
	}
	render() {	  
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Address Line 1:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="address1" type="text" className="edit-field" value={this.state.address1} onChange={handleInputChange.bind(this)} placeholder="Address Line 1"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Address Line 2:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="address2" type="text" className="edit-field" value={this.state.address2} onChange={handleInputChange.bind(this)} placeholder="Address Line 2"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>City:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="city" type="text" className="edit-field" value={this.state.city} onChange={handleInputChange.bind(this)} placeholder="City"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>State:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="state" type="text" className="edit-field" value={this.state.state} onChange={handleInputChange.bind(this)} placeholder="State"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Postal Code:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="postalCode" type="text" className="edit-field" value={this.state.postalCode} onChange={handleInputChange.bind(this)} placeholder="Postal Code"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Phone:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="phone" type="text" className="edit-field" value={this.state.phone} onChange={handleInputChange.bind(this)} placeholder="Phone Number"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Email:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="email" type="text" className="edit-field" value={this.state.email} onChange={handleInputChange.bind(this)} placeholder="Email Address"/>
									</Col>
								 </Row>
								 <Row className="mt-2">
								    <Col md={6} className="text-left">
										<Button type="button" size="md" color="success" className="edit-button" onClick={this.handleSubmit}>Submit</Button>{' '}
									</Col>
								    <Col md={6} className="text-left">
										<Button type="button" size="md" color="danger" className="edit-button" onClick={this.handleClose}>Cancel</Button>{' '}
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(OrganizationsEditDetails);