import React from 'react';
import '../App.css';
import {Container, Row, Col, Input, Button} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import {handleGetFetch, handleInputChange, handleSortClick} from './common.js';
import { FaSort } from 'react-icons/fa';

class OrganizationsAdminActivityLog extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   searchInput: "",
		   isLoading: false,
		   currentPage: 0,
		   listings: []
	   }
	}
	componentDidMount()  {
		this.setState({isLoading: true});
		handleGetFetch("organization")
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, currentPage: result.data.current_page, listings: result.data.data});
		})
	}
	handleChangeView(myView) {
		this.props.handleChangeView(myView);
	}
	render() {
      let columnData = {
		  "created_at": "Timestamp",
		  "user": "User",
		  "note": "Note"
	  }			 
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border">
						  <Row>
							  <Col md={9}>
								 <div className="center-content">
  							        <Input name="searchInput" type="text" className="search-field" value={this.state.searchInput} onChange={handleInputChange.bind(this)} ref={(input) => { this.searchInput = input; }} placeholder="Search"/>
							     </div>
							  </Col>
							  <Col md={2}>
								 <div className="center-content">
								    <Button type="button" size="md" color="primary" className="default-button">FILTER</Button>{' '}
								 </div>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
				  <Row>
					   <Col md={12}>
				          <Link onClick={this.handleChangeView.bind(this,'/OrganizationsDetail')}>Return to Organization Overview</Link>
					   </Col>
				  </Row>
				  <Row>
					  <div className="content-section-no-border">
						  <Listing data={columnData} header={true} handleSortClick={handleSortClick}/>
						  {this.state.listings &&
						     this.state.listings.map((data) => (<Listing key={data.id} data={data}/>))
						  }
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export class Listing extends React.Component{
	
  render(){
	var data=this.props.data;
	var timestamp=data.created_at;
	var user=data.user;
	var note=data.note;
	
    return(
       <Row>
		  <Col md={2} className="text-left">
		     <span className="text-normal-gray">{timestamp}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,0)}><FaSort/></button>}</span>
	      </Col>
		  <Col md={2} className="text-left">
		     <span className="text-normal-gray">{user}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,1)}><FaSort/></button>}</span>
		  </Col>
		  <Col md={8} className="text-left">
		     <span className="text-normal-gray">{note}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,2)}><FaSort/></button>}</span>
		  </Col>
	   </Row>
     )
  }
}

export default withRouter(OrganizationsAdminActivityLog);