import React from 'react';
import '../App.css';
import {Container, Button, Row, Col} from 'reactstrap';
import {withRouter} from "react-router-dom";
import axios from "axios";

class UploadW9 extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   isLoading: false,
		   w9file: null,
		   w9fileError: "",
		   sponsorParams: this.props.sponsorParams,
		   organizationId: this.props.organizationId
	   }
	   this.fileChangeHandler = this.fileChangeHandler.bind(this);
	   this.handleFileUpload = this.handleFileUpload.bind(this);
	   this.handleClose = this.handleClose.bind(this);
	}
	handleClose() {
	   this.props.handleClose();
	}
	fileChangeHandler(event) {
       this.setState({ w9file: event.target.files[0] });
	}
	handleFileUpload() { 
        if (!this.state.w9file) {
			alert("No file selected.");
			return false;
		}
		this.setState({isLoading: true});
		const formData = new FormData()
		formData.append("myFile", 
                        this.state.w9file, 
                        this.state.w9file.name);
		const config = {
		   headers: {
			  'content-type': 'multipart/form-data'
	  	   }
		};		
		axios.post("https://api.mdfm.app/api/files" + this.state.sponsorParams + "&organization_id=" + this.state.organizationId + "&file_type=w9", formData, config)
		.then(res => { // then print response status
		    console.log(res);
			var w9file = this.state.w9file;
			var w9fileError = "";

			if (res.status !== 200 || res.statusText !== "OK") {
				w9fileError = "File transfer not completed successfully.";
				w9file = null;
			}
			if (res.data.mimetype !== "image/png" && 
				res.data.mimetype !== "image/jpg" && 
				res.data.mimetype !== "image/jpeg" && 
				res.data.mimetype !== "image/pdf" && 
				res.data.mimetype !== "image/gif") {
				w9fileError = "JPEG, GIF and PNG, and PDF image formats supported.";
				w9file = null;
			}
			this.setState({isLoading: false, w9file: w9file, w9fileError: w9fileError});
	    })		
	}	
	render() {	  
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
								 <Row>
								    <Col md={8} className="text-left">
									   <input type="file" onChange={this.fileChangeHandler} />
									</Col>
								 </Row>
								 <Row className="mt-2">
								    <Col md={6} className="text-left">
										<Button type="button" size="md" color="success" className="edit-button" onClick={this.handleFileUpload}>Upload</Button>{' '}
									</Col>
								    <Col md={6} className="text-left">
										<Button type="button" size="md" color="danger" className="edit-button" onClick={this.handleClose}>Cancel</Button>{' '}
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(UploadW9);