import React from 'react';
import '../App.css';
import {Container, Row, Col, Button} from 'reactstrap';
import {withRouter, Link} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {handleGetFetch, returnDollars} from './common.js';

class SponsorDashBoard extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };
	constructor(props){
	   super(props);
	   let sponsorParams = this.props.location.search;
       this.state= {
		   isLoading: false,
		   data: [],
		   sponsorParams: sponsorParams
	   }
	}
	handleChangeView(myView) {
		this.props.handleChangeView(myView);
	}
	handleChangeViewMultiple(myView, searchPeriod) {
		this.props.handleChangeViewMultiple(myView, searchPeriod);
	}
	componentDidMount()  {
		let url = "sponsor/dashboard" + this.state.sponsorParams;
		this.setState({isLoading: true});
		handleGetFetch(url)
		.then((result) => {
		   this.setState({isLoading: false, data: result.data.data});
		})
	}
	render() {
	  var data=this.state.data;
		const last_payment_date = data.last_payment ? data.last_payment.pay_period : 'none';
		const last_payment_amount = data.last_payment ? data.last_payment.payment_amount : 0.00;
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Month to Date Sales:</strong> {returnDollars(data.sales_current_month_total)} ({data.sales_current_month_count} Sales)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Registered Users:</strong> {data.registered_users} ({data.active_users} active in last 12 months)</span>
								    </Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Estimated Share (@ {data.sales_current_month_share_percentage * 100}%):</strong> {returnDollars(data.sales_current_month_share_total)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Last Month's Sales:</strong> {returnDollars(data.sales_last_month_total)} ({data.sales_last_month_count} sales) </span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left">
								       <span className="text-normal-gray"><strong>Estimated Share (@ {data.sales_last_month_share_percentage * 100}%):</strong> {returnDollars(data.sales_last_month_share_total)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
							     <Row>
								    <Col className="text-left mt-3">
										<span className="text-normal-gray"><strong>Most Recent Earnings Payment:</strong> Amount: {returnDollars(last_payment_amount)} Date: {last_payment_date} <Button type="button" size="xs" color="primary" onClick={this.handleChangeView.bind(this,'/SponsorPayments')}>View</Button>{' '}</span>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Sales Year To Date:</strong> {returnDollars(data.sales_year_to_date)} ({data.sales_year_to_count} Sales)</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Lifetime Share Earnings:</strong> {returnDollars(data.lifetime_payouts)}</span>
									</Col>
									<Col>
									</Col>
								 </Row>
								 <Row>
								    <Col className="text-left mt-3">
								       <span className="text-normal-gray"><strong>Registered Since:</strong> {data.registered_since} <strong>Last Sale:</strong> {data.last_sale} <strong>Last Payment:</strong> {last_payment_date}</span>
									</Col>
								 </Row>
							  </Col>
						  </Row>
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(withCookies(SponsorDashBoard));