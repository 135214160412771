import React from 'react';
import './App.css';
// using HashRouter because of backend server needs
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import Admin from './components/admin';
import Sponsor from './components/sponsor';

class App extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };	
	constructor(props){
	   super(props);
	   const { cookies } = props;
	   // adminView contains current console view to display for admin panel
	   // sponsorView contains current console view to display for sponsor panel
       this.state= {
		   adminView: cookies.get('adminView') || '/DashBoard',
		   sponsorView: cookies.get('sponsorView') || '/DashBoard'
	   }
	}
	render() {
		
		// grab the current pathname from DOM
        var path = window.location.pathname;
	    console.log(path);
	
	    // /DashBoard and / call the same library but within the site the DashBoard button goes to /DashBoard not /
  	    return (
	        <div className="App">
		        <Router forceRefresh={false}>
			        <Switch>
						<Route path="/Sponsors">
						   <Sponsor myView = {this.state.sponsorView}/>
						</Route>
				        <Route path="/DashBoard">
					  	   <Admin myView = {this.state.adminView}/>
					    </Route>
				        <Route path="/">
					  	   <Admin myView = {this.state.adminView}/>
					    </Route>
		  	        </Switch>
		        </Router>	
		    </div>
		)
	}
}

export default withCookies(App);
