import React from 'react';
import '../App.css';
import {Container, Row, Col, Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {withRouter} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import {handleGetFetch} from './common';

// various libaries for the different views. No admin libraries are called from here. Only sponsor side.

import SponsorDashBoard from './sponsor-dashboard.js';
import SponsorOrders from './sponsor-orders';
import SponsorPayments from './sponsor-payments';
import SponsorProfile from './sponsor-profile';
import SponsorEditPhone from './sponsor-edit-phone';
import SponsorEditEmail from './sponsor-edit-email';
import UploadW9 from './upload-w9';
//import Login from './login';

class Sponsor extends React.Component {
    static propTypes = {
	   cookies: instanceOf(Cookies).isRequired
    };	
	constructor(props){
	   super(props);
	   const { cookies } = props;
       // props.myView is passed by App.js after App.js pulls the cookie
	   // searchCriteria is mtd (month to day), last-month
	   // userName is set by login or obtained from shopify side and displayed on console
	   // modalTitle, modalBody and modalSize are used with the popup modal screens
	   // modal indicates is a modal is open or not at the time
	   var sponsorParams = this.props.location.search;
       this.state= {
		   myView: this.props.myView,
		   searchCriteria: cookies.get('searchCriteria') || "mtd", 
		   sponsorParams: sponsorParams,
		   organizationId: null,
		   data: [],
		   userName: "",
		   modalTitle: "",
		   modalBody: "",
		   modelSize: "md",
		   modal: false,
	   }
	   // bind various functions so they get use of "this"
	   this.handleDashBoard = this.handleDashBoard.bind(this);
	   this.handleChangeView = this.handleChangeView.bind(this);
	   this.handleChangeViewMultiple = this.handleChangeViewMultiple.bind(this);
	   this.handleClose = this.handleClose.bind(this);
	   this.openEditPhone = this.openEditPhone.bind(this);
	   this.openEditEmail = this.openEditEmail.bind(this);
	   this.openUploadW9 = this.openUploadW9.bind(this);
	   this.toggle = this.toggle.bind(this);
	   this.setModal = this.setModal.bind(this);
	}
	componentDidMount()  {
		this.setState({isLoading: true});
		handleGetFetch("sponsor/user" + this.state.sponsorParams)
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, data: result.data, userName: result.data.first_name + " " + result.data.last_name, organizationId: result.data.organization_id});
		})
	}
	handleDashBoard() {
	   // change view to main dashboard
	   this.handleChangeView('/DashBoard');
	}
	handleChangeView(myView) {
		// set myView state variable to change screen view
		this.setState({myView: myView});
	    const { cookies } = this.props;
	    cookies.set('sponsorView', myView, { path: '/' });
	}
	handleChangeViewMultiple(myView, searchPeriod) {
		// set myView state variable and sponsorView cookie to change screen view
        // set searchPeriod cookie for use in View Orders screen view
		this.handleChangeView(myView);
		this.setState({searchPeriod: searchPeriod});
	    const { cookies } = this.props;
	    cookies.set('searchPeriod', searchPeriod, { path: '/' });
	}
    handleClose() {
		// close modal window
	    this.toggle();
    }
	toggle() {
	    // toggle modal window open/closed
	    this.setState(prevState => ({modal: !prevState.modal}))
	}
	setModal(title, body, size) {
	    // set contents of modal window
	    this.setState({
		   modalTitle:title,
		   modalBody:body,
		   modalSize:size
	    });
	}	
    openEditPhone(data) {
		// open edit phone modal
        this.setModal("Change Phone", <SponsorEditPhone data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openEditEmail(data) {
		// open edit email modal
        this.setModal("Change Email", <SponsorEditEmail data={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
    openUploadW9(data) {
		// open upload w9 file modal
        this.setModal("Upload W9 Document", <UploadW9 sponsorParams={this.state.sponsorParams} organizationId={data} handleClose={this.handleClose}/>, "md"); 
	    this.toggle();
    }
	render() {
      let myView = this.state.myView;

	  return (
		  <>
			  <Container fluid>
				  <div className="sticky-item">
					  <Modal isOpen={this.state.modal} toggle={this.toggle} scrollable={true} ref={el => (this.modal = el)} size={this.state.modalSize}>
						  <ModalHeader toggle={this.toggle}>{this.state.modalTitle}</ModalHeader>
						  <ModalBody>
							{this.state.modalBody}
						  </ModalBody>
					  </Modal>
				  </div>
				  <Row>
					  <div className="header-section">
						  <Row>
							  <Col className="text-left">
								 <span className="header-title">Market Day Fundraising Manager (MDFM)</span>
							  </Col>
							  <Col className="text-right">
								 <span className="text-normal-gray">Logged in as {this.state.userName}</span>
							  </Col>
						  </Row>	
						  <Row>
							  <Col className="text-left">
								 <span className="text-normal-gray">Version 1.0 Copyright 2020</span>
							  </Col>
						  </Row>
				      </div>
				  </Row>
				  <Row>
					  <div className="section-no-border">
						  <Row>
							  <Col md={2}>
								 <Button type="button" size="lg" color="primary" onClick={this.handleDashBoard} className="dashboard-button">Dashboard</Button>{' '}
								 <Button type="button" size="lg" color="primary" onClick={this.handleChangeView.bind(this,'/SponsorOrders')} className="dashboard-button">Orders</Button>{' '}
								 <Button type="button" size="lg" color="primary" onClick={this.handleChangeView.bind(this,'/SponsorPayments')} className="dashboard-button">Payment History</Button>{' '}
								 <Button type="button" size="lg" color="primary" onClick={this.handleChangeView.bind(this,'/SponsorProfile')} className="dashboard-button">My Profile</Button>{' '}
							  </Col>
							  <Col md={10}>
								 <div className="content-section">
									{myView.includes("/DashBoard") &&
									   <SponsorDashBoard handleChangeView={this.handleChangeView} handleChangeViewMultiple={this.handleChangeViewMultiple}/>
									}
									{myView.includes("/SponsorOrders") &&
									   <SponsorOrders searchCriteria={this.state.searchCriteria}/>
									}
									{myView.includes("/SponsorPayments") &&
									   <SponsorPayments/>
									}
									{myView.includes("/SponsorProfile") &&
									   <SponsorProfile organizationId={this.state.organizationId} openEditPhone={this.openEditPhone} openEditEmail={this.openEditEmail} openUploadW9={this.openUploadW9}/>
									}
								 </div>
							  </Col>
						  </Row>
					  </div>
				  </Row>
				  <Row>
					  <div className="footer-section">
						  <Row>
							 <div className="center-content">
								<span className="text-normal-gray">Version 1.0 Copyright 2020</span>
							 </div>
						  </Row>
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(withCookies(Sponsor));