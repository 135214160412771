import React from 'react';
import '../App.css';
import {Container, Row, Col, Input, Button} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {handleGetFetch, handleInputChange, handleCheckChange, handleSortClick, firstUpper} from './common.js';
import { FaSort } from 'react-icons/fa';

class PaymentsList extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   searchInput: "",
		   isLoading: false,
		   currentPage: 0,
		   listings: [],
		   columnHeaderCheck: false
	   }
	   this.handleBatchPayments = this.handleBatchPayments.bind(this);
	   this.openMarkAsPaid = this.openMarkAsPaid.bind(this);
	}
	handleBatchPayments() {
		this.props.handleChangeView('/PaymentsRunPaymentBatch');
	}
	componentDidMount()  {
		console.log("In componentDidMount");
		this.setState({isLoading: true});
		handleGetFetch("payment")
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, currentPage: result.current_page, listings: result.data});
		})
	}
	openMarkAsPaid() {
		this.props.openMarkAsPaid(null);
	}
	render() {
      let columnData = {
		  "pay_period": "Period",
		  "payment_amount": "Amount",
		  "type": "Type",
		  "payment_status": "Payment Status",
		  "payment_date": "Payment Date",
		  "payment_comment": "Comment"
	  }			 
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border">
						  <Row>
							  <Col md={4}>
								 <div className="center-content">
  							        <Input name="searchInput" type="text" className="search-field" value={this.state.searchInput} onChange={handleInputChange.bind(this)} ref={(input) => { this.searchInput = input; }} placeholder="Search"/>
							     </div>
							  </Col>
							  <Col md={2}>
								 <div className="center-content">
								    <Button type="button" size="md" color="primary" className="default-button">FILTER</Button>{' '}
								 </div>
							  </Col>
							  <Col md={3}>
								 <div className="center-content">
								    <Button type="button" size="md" color="info" onClick={this.handleBatchPayments} className="default-button">NEW PAYMENT BATCH</Button>{' '}
								 </div>
							  </Col>
							  <Col md={2}>
								 <div className="center-content">
								    <Button type="button" size="md" color="info" className="default-button" onClick={this.openMarkAsPaid}>MARK AS PAID</Button>{' '}
								 </div>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
				  <Row>
					  <div className="content-section-no-border">
						  <Listing data={columnData} columnHeaderCheck={this.state.columnHeaderCheck} header={true} handleSortClick={handleSortClick.bind(this)}/>
						  {this.state.listings &&
						     this.state.listings.map((data) => (<Listing key={data.id} data={data} columnHeaderCheck={false}/>))
						  }
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export class Listing extends React.Component{
  render(){
	var data=this.props.data;
	var columnHeaderCheck=this.props.columnHeaderCheck;
	var organizationName=data.organization_name;
	var amount=data.amount;
	var type=data.type;
	var period=data.period;
	var paymentStatus=firstUpper(data.payment_status);
	var paymentDate=data.payment_date;
	
    return(
       <Row>
		  <Col md={1} className="text-left">
			 <span className="text-normal-gray-column">
				<div className="form-check">	
				   <input type="checkbox" className="form-check-input text-center" id="columnHeaderCheck" name="columnHeaderCheck" checked={columnHeaderCheck} onChange={handleCheckChange.bind(this)}/>
			       <label className="form-check-label" htmlFor="columnHeaderCheck"></label>
				</div>
			 </span>
		  </Col>
		  <Col md={4} className="text-left">
		     <span className="text-normal-gray">{organizationName}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,0)}><FaSort/></button>}</span>
	      </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{amount}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,1)}><FaSort/></button>}</span>
	      </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{type}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,2)}><FaSort/></button>}</span>
		  </Col>
		  <Col className="text-left">
		     <span className="text-normal-gray">{period}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,3)}><FaSort/></button>}</span>
		  </Col>
		  <Col md={2} className="text-left">
		     <span className="text-normal-gray">{paymentStatus}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,4)}><FaSort/></button>}</span>
		  </Col>
		  <Col md={2} className="text-left">
		     <span className="text-normal-gray">{paymentDate}{this.props.header && <button className="link-button" onClick={this.props.handleSortClick.bind(this,5)}><FaSort/></button>}</span>
		  </Col>
	   </Row>
     )
  }
}

export default withRouter(PaymentsList);
