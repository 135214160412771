import React from 'react';
import '../App.css';
import {Container, Input, Button, Row, Col} from 'reactstrap';
import {withRouter} from "react-router-dom";
import {handlePost, handleInputChange} from './common.js';

class PaymentsEditDetails extends React.Component {
	constructor(props){
	   super(props);
       this.state= {
		   isLoading: false,
		   periodStart: this.props.data.periodStart,
		   periodEnd: this.props.data.periodEnd,
		   profileShare: this.props.data.profileShare,
		   dataReturned: []
	   }
	   this.handleClose = this.handleClose.bind(this);
	   this.handleSubmit = this.handleSubmit.bind(this);
	}
	handleClose() {
		this.props.handleClose();
	}
	handleSubmit() {
		this.setState({isLoading: true});
		// field name to be determined
		var data = {"??????" : this.state.periodStart,
					"?????" : this.state.periodEnd,
					"????" : this.state.profileShare
		};
		// url to be determined
		handlePost("shopify/admin/organization/update", data)
		.then(res=>res.json())
		.then((result) => {
  		   console.log(result);		
		   this.setState({isLoading: false, dataReturned: result});
		})
	}
	render() {	  
	  return (
		  <>
			  <Container fluid>
				  <Row>
					  <div className="section-no-border-margins">
						  <Row>
							  <Col md={12} className="text-left">
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Period Start:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="periodStart" type="text" className="edit-field" value={this.state.periodStart} onChange={handleInputChange.bind(this)} placeholder="mm/dd/yyyy"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Period End:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="periodEnd" type="text" className="edit-field" value={this.state.periodEnd} onChange={handleInputChange.bind(this)} placeholder="mm/dd/yyyy"/>
									</Col>
								 </Row>
								 <Row>
								    <Col md={4} className="text-left">
										<strong>Profile Share %:</strong>
									</Col>
								    <Col md={8} className="text-left">
									   <Input name="profileShare" type="text" className="edit-field" value={this.state.profileShare} onChange={handleInputChange.bind(this)} placeholder="ex: 10"/>
									</Col>
								 </Row>
								 <Row className="mt-2">
								    <Col md={6} className="text-left">
										<Button type="button" size="md" color="success" className="edit-button" onClick={this.handleSubmit}>Submit</Button>{' '}
									</Col>
								    <Col md={6} className="text-left">
										<Button type="button" size="md" color="danger" className="edit-button" onClick={this.handleClose}>Cancel</Button>{' '}
									</Col>
								 </Row>
							  </Col>
						  </Row>	
				      </div>
				  </Row>
			  </Container>
		  </>
		)
	}
}

export default withRouter(PaymentsEditDetails);