import React from 'react';
import axios from 'axios';
import NumberFormat from 'react-number-format';

// common input handler
function handleInputChange(event) {
   const value = event.target.value;
   const name = event.target.name;
   this.setState({[name]: value});
}
// check check box handler
function handleCheckChange(event) {
   const value = event.target.checked;
   const name = event.target.name;
   this.setState({[name]: value});
}
// using fetch() right now. May be changed to axios later to eliminate one library.
async function handleGetFetch(url) {
   const uri = 'https://api.mdfm.app/api/' + url;
   return axios.get(uri, {
      method: 'GET',
	  headers: {
  	     'Content-Type': 'application/json',
          'Accept': 'application/json'
	  },
   })
}

function handlePost(url, data) {
   const uri = 'https://api.mdfm.app/api/' + url;
   return fetch(uri, {
      method: 'POST',
      body: JSON.stringify(data),
	  headers: {
  	     'Content-Type': 'application/json',
          'Accept': 'application/json'
	  },
   })
}

// handles column clicks for sorting purposes
function handleSortClick(value) {
  alert("clicked sort! " + value);
}

// uppercase first letter of string
function firstUpper(myString) {
    if (myString) return myString.charAt(0).toUpperCase() + myString.slice(1);
}

function returnDollars(myString) {
    return <NumberFormat value={myString}  displayType={'text'} thousandSeparator={true} prefix={'$'} decimalScale={2} fixedDecimalScale={true}/>;
}

export {handleInputChange, handleCheckChange, handleGetFetch, handleSortClick, handlePost, firstUpper, returnDollars};
